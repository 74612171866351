<template>
  <div>
    <router-link
      class="btn btn-primary mb-3"
      :to="{name: 'sign-loa-add'}"
    >
      Add LOA
    </router-link>

    <div class="table-responsive">
      <table class="table table-hover text-nowrap">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Contact Person</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Submitted On</th>
            <th
              colspan="2"
              class="text-center"
            >
              Approved Range
            </th>
            <th>Status</th>
          </tr>
          <tr>
            <th />
            <th />
            <th />
            <th />
            <th />
            <th class="text-center">
              From
            </th>
            <th class="text-center">
              To
            </th>
            <th />
          </tr>
        </thead>
        <tbody v-if="items">
          <tr
            v-for="item in items"
            :key="item.id"
          >
            <td>{{ item.carrier_name }}</td>
            <td>{{ item.contact_name }}</td>
            <td>{{ item.phone_number }}</td>
            <td>{{ item.email }}</td>
            <td>{{ getDate(item.created_on) }}</td>
            <td class="text-center">
              {{ item.tn_range_begin }}
            </td>
            <td class="text-center">
              {{ item.tn_range_end }}
            </td>
            <td>{{ item.status }}</td>
          </tr>
        </tbody>
      </table>
      <a-pagination
        v-if="total"
        class="pagination-right"
        :default-current="1"
        :total="total"
        @change="pagination($event)"
      />
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import moment from '@/plugins/moment'

export default {
  data() {
    return {
      step: 10,
      skip: 0,
      total: null,
      items: null,
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    loadItems() {
      axios
        .get(`/signing/loa?order_dir=asc&order_by=created_on&take=10&skip=${this.skip}`)
        .then((res) => {
          this.items = res.data.data
          this.total = res.data.count > this.step ? res.data.count : res.data.count
        })
        .catch((err) => {
          console.log(err)
        })
    },
    deleteItem(id) {
      axios
        .delete(`/signing/loa/${id}`)
        .then((res) => {
          notification.success({
            message: 'Deleted',
            //  description: 'You have successfully logged in!',
          })
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getDate(date) {
      return moment(date)
    },
    pagination(int) {
      this.skip = 0
      this.skip += this.step * int - this.step
      this.loadItems()
    },
  },
}
</script>

<style scoped>
.pagination-right {
  text-align: right !important;
  margin-right: 86px !important;
}
</style>